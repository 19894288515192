// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-narative-gatsby-theme-novela-src-templates-author-template-tsx": () => import("./../../../node_modules/@narative/gatsby-theme-novela/src/templates/author.template.tsx" /* webpackChunkName: "component---node-modules-narative-gatsby-theme-novela-src-templates-author-template-tsx" */),
  "component---src-narative-gatsby-theme-novela-templates-article-template-tsx": () => import("./../../../src/@narative/gatsby-theme-novela/templates/article.template.tsx" /* webpackChunkName: "component---src-narative-gatsby-theme-novela-templates-article-template-tsx" */),
  "component---src-narative-gatsby-theme-novela-templates-articles-template-tsx": () => import("./../../../src/@narative/gatsby-theme-novela/templates/articles.template.tsx" /* webpackChunkName: "component---src-narative-gatsby-theme-novela-templates-articles-template-tsx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-release-notes-js": () => import("./../../../src/pages/release-notes.js" /* webpackChunkName: "component---src-pages-release-notes-js" */),
  "component---src-pages-sre-js": () => import("./../../../src/pages/sre.js" /* webpackChunkName: "component---src-pages-sre-js" */)
}

